<template>
  <div class="comment-detail-wrap" v-if="entity">
    <div class="comment-detail">
        <div class="comment-title">
            <router-link :to="{ name: 'Personal', query: { uid: entity.uid } }">
                <img :src="entity.user && entity.user.userImg" alt="" />
                {{ entity.user.nickName }}
            <span class="comment-master-tip">楼主</span>
            </router-link>
        </div>
        <div class="comment-content" v-html="entity.content"></div>
        <div class="comment-date" data-comment-id="183">
            <span>{{ dateFormat(timeFormat(entity.createTime)) }}</span>
        </div>
    </div>
    <div class="comment">
        <div class="comment-list-title">
            全部回复
        </div>
        <ul>
            <li v-for="item in erjiPostList" :key="item.id">
                <router-link class="comment-avatar" :to="{ name: 'Personal', query: { uid: item.uid } }">
                     <img :src="item.user && item.user.userImg">
                </router-link>
                <div class="comment-info">
                    <div class="nick-name">{{ item.user.nickName }}</div>
                    <div class="comment-content">{{ item.content }}</div>
                    <div class="comment-date">
                         <span>{{ dateFormat(timeFormat(item.createTime)) }}</span>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <publish-comment @submit="subComment" v-model="comment"></publish-comment>
    </div>
    <loading :options="loadOption" v-else></loading>
    
</template>

<script lang="ts">
import { defineComponent, ref, inject } from "vue";
import axios from "@/api/axios";
import { useRoute } from "vue-router";
import { timeFormat } from "@/utils/dateFormat";
import PublishComment from "@/components/common/PublishComment.vue";
import qs from "qs";
import Toast from "@/components/UI/Toast";
import Loading from "@/components/UI/Loading";
export default defineComponent({
  name: "CourseCommentDetail",
  components: {
    PublishComment,
    Loading
  },
  setup(props, ctx) {

    const route = useRoute();
    const id = route.query.id;
    const entity = ref<any>(null);
    const erjiPostList = ref<object>([]);
    const loadOption = {
      text: "加载中...",
      color: "#b9135a",
      textColor: "#b9135a",
    };
    axios.get("/M/Discover/CourseCommentDetail/" + id).then(res => {
        const data = res.data.obj;
        entity.value = data.entity;
        erjiPostList.value = data.erjiPostList;
    })

    function dateFormat(datetime: string) {
        return datetime.substr(0,16)
    }

    const comment = ref("");
    const my = inject("userInfo") as object;
    function subComment() {
      if (!comment.value) {
        Toast({
          type: "warning",
          title: "请先输入评论再提交哦~",
        });
        return false;
      }
      const data = qs.stringify({
        id,
        content: "回复"+ entity.value.user.nickName + "：" + comment.value,
      });
      axios
        .post("/M/CourseServer/DoPostCourseCommentPost", data)
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
            });
            (erjiPostList.value as object[]).unshift({
              ...res.data.obj,
              isAlreadyZan: 0,
              user: {
                ...my,
                userImg: (my as any).userImg,
              },
            });
            comment.value = "";
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          const message = err.message ? err.message : "未知错误，请联系管理员";
          Toast({
            type: "error",
            title: message,
          });
        });
    }
    return {
      entity,
      erjiPostList,
      dateFormat,
      timeFormat,
      subComment,
      comment,
      loadOption
    };
  },
});
</script>

<style lang="scss" scoped>
    .comment-detail-wrap{
        padding-bottom: 110px;
    }
    .comment-detail{
  
  margin: 15px auto;
  margin-bottom: 0;
  border-bottom: 1px solid #eee;
  padding: 18px 10px;
  box-sizing: border-box;
}

.comment-title{
  line-height: 35px;
  color: #666666;
  font-size: 16px;
  position: relative;

}

.comment-title img{
 
  width: 35px;
  height: 35px;
  vertical-align: middle;
  position: relative;
  top: -2px;
  border-radius: 50%;
}

.comment-master-tip{
    display: inline-block;
    height:14px;
    background-color: #6cb8d1;
    border-radius: 2px;
    border: solid 1px #6cb8d1;
    line-height: 14px;
    padding: 0 3px;
    color: #ffffff;
    font-size: 9px;
    margin-left: 5px;
}

.comment-content{
  margin-top: 10px;
    font-size: 15px;
    line-height: 22px;
    color: #444444;
}

.comment-date{
  margin-right: 10.013px;
  color: #999999;
  font-size:13.988px;
}

.comment-operation{
  width: 86px;
    height: 32px;
    border-radius:16px;
    border: solid 1px #999999;
    line-height: 32.513px;
    text-align: center;
    color: #666666;
    font-size: 12px;
    margin: 0 auto;
    margin-top: 17px;
  position: relative;

}

.comment-operation img{
  width: 13.012px;
  height: 13.012px;
  margin-right: 4.988px;
  vertical-align: middle;
  position: relative;
  top: -1.988px;
}



.comment .title{
  box-sizing: border-box;
  padding: 0 10.013px;
  color: #444444;
  font-size: 15px;
  height: 40.012px;
  line-height:  40.012px;
  border-bottom: 1px solid #eee;
  text-align: left;
}

.comment li{
  position: relative;
  padding: 17.512px 10.013px;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
  overflow: hidden;
  padding-left: 55.013px;
}

.comment li .comment-avatar{
  position: absolute;
  left: 10.013px;
  top: 17.512px;
  border-radius: 50%;
}
.comment-list-title {
    box-sizing: border-box;
    padding: 0 0.267rem;
    color: #444444;
    font-size: 0.4rem;
    height: 1.067rem;
    line-height: 1.067rem;
    border-bottom: 1px solid #eee;
}

.comment li .comment-avatar img{
  display: block;
  width: 34.988px;
  height: 34.988px;

  border-radius: 50%;
}

.nick-name{
  color: #666666;
  font-size: 16.012px;
  position: relative;
  padding-right: 75px;
}
.comment-info{
  text-align: left;
}
.comment-content{
  margin-top: 10.013px;
  font-size: 15px;
  line-height: 22.012px;
  color: #444444;
} 
.comment-date{
  margin-top: 10.013px;
  color: #999999;
  font-size: 13.988px;

}

.comment-date span{
  display: inline-block;
  margin-right: 10.013px;
}
</style>